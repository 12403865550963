body {font: 14px/1.55 'CiscoSansTTLight', Arial,'Liberation Sans',FreeSans,sans-serif;}
.glasspane {background: #049fd9; height: 40px; width: 850px; display: inline; float: left;}
.glasspane h1 {opacity: 1; color: #fff; margin-top: 2px; margin-bottom: 0; font-weight: normal; font-size:25px; padding-left:10px}

abbr, address, article, aside, audio, blockquote, body, canvas, caption, cite, code, dd, del, details, dfn, div, dl, dt, em, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, p, pre, q, samp, section, small, span, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, ul, var, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    list-style: none;
    font-weight: 200;
    margin-top: 30px;
}

canvas{
  width: 160px;
  height: 160px;
}

a {
    text-decoration: none;
    color: #0ab7d7;;
}

.div-standard{
  {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    list-style: none;
    font-weight: 200;
    margin-top: 30px;
}
}

.onboarding-tos-link {
    color: #aeaeaf;
}

.link {
    color: #0ab7d7;
}

.onboarding-bg{
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: auto;
    max-width: 100vw;
    width: 100%;
    background: center center/cover no-repeat fixed;
    background-color: rgba(0, 0, 0, 0);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-clip: border-box;
    background-origin: padding-box;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
    color: #858688;
    -webkit-box-align: center;
    align-items: center;
    z-index: 0;
}

.onboarding-form {
    text-align: center;
    margin-top: 90px;

}

.onboarding-form-qrcode-displayed{
  margin-top: 90px;
}
/* #qrcode:hover + .onboarding-form-logo{
  transform: scale(0.7);
  padding-top: 0px;
  transition: transform .2s;
} */

/* #qrcode:hover + .onboarding-form{
  transform: scale(0.7);
  margin-top: 17px;
  transition: transform .2s;

} */


.onboarding-form-logo-section {
/*    -ms-flex-item-align: center;*/
/*    -ms-grid-row-align: center;*/
    align-self: center;
    color: #858688;
    margin: 1rem 0 0;
    text-align: center;
/*    -ms-flex: 0 0 auto;*/
    flex: 0 0 auto;
}

.onboarding-form-logo{
    background: transparent url(/5c09960cb7ba45d784b9ced4232c0059.png) 100% no-repeat;
    background-size: auto auto;
    background-size: 100%;
    width: 200px;
    height: 170px;
    margin-left: 0%;
}

.onboarding-form-instructions {
    color: #343537;
    font-size: 20px;
    margin: .5rem 0;
    width: 100%;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-family: "CiscoSansTTExtraLight","Helvetica Neue",Arial;
}
.button-centered {
  justify-content: center;
  align-items: center;
  color: #fff;
  border: 1px solid rgba(0,0,0,.08);
  background-color: #6EBF49;
  cursor: pointer;
  box-sizing: border-box;
  width: 130px;
  margin-left: 33%;
  margin-top: 12%;

}

.button--md.button--wide {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 10px;
}


.onboarding-button {
    min-width: 60%;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
}

.button--md {
    font-family: "CiscoSansTTLight","Helvetica Neue",Arial;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: .3px;
    min-width: 79.2px;
    height: 44px;
    line-height: normal;
}

.button--pill {
    border-radius: 100px;
}

.button {
    justify-content: center;
    align-items: center;
    color: #fff;
    border: 1px solid rgba(0,0,0,.08);
    background-color: #6EBF49;
    cursor: pointer;
    box-sizing: border-box;
}

.onboarding-footer {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    margin: .3rem;
    color: #858688;
    width: 100%;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
}

.onboarding-footer--sm {
    height: 26.4px;
    width: 323.2px;
    font-size: 10px;
    flex: 0 0 auto;
    color: #aeaeaf;
    align-content: center;
    padding-left: 40px;
}

.onboarding-cisco-footer {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    bottom: 20px;
    font-size: 32px;
    height: 32px;
    width: 61px;
    left: 0;
    right: 0;
    margin: 0 auto;
    line-height: 40px;
    color: #aeaeaf;
}

.icon {
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    color: #343537;
    font-size: 22px;
    line-height: 20px;
    height: 24px;
    width: 24px;
    background: transparent;
    border: none;
    padding: 0;
}

.onboarding-modal{
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    width: 400px;
    height: 570px;
    background-color: hsla(0,0%,100%,.8);
    box-shadow: 0 1px 3px 0 rgba(41,41,41,.18);
    border-radius: 12px;
    z-index: 100;
    position: relative;
    font-family: "CiscoSansTTLight",Helvetica Neue,Arial;
    margin: 3vw;
}

.table {margin: 10px 0 0 0; float:left; background-color: #eee; padding: 10px;}
td:first-child {width: 116px;}
input, textarea {width: 700px; display: block;}
textarea {height: 140px;}
.wrapper {width: 900px; float:left;}

#qrcode {

}
.qrcode-displayed {
  width:160px;
  height:160px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 30%;
  /* border:#049fd9 solid 1px; */
  transition: transform .2s;
}

/* #qrcode:hover  {
  transform: scale(1.5);
} */



.qrcode_title {
  font-size: 1.25rem;
    line-height: 1.5rem;
    color: #aeaeaf;
    margin-bottom: 0.8rem;
    padding: 0 1rem;
    letter-spacing: 1px;
    word-wrap: break-word;
    font-family: "CiscoSansTT Extra Light","Helvetica Neue",Helvetica,Arial,sans-serif;
}
